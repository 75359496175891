<template>
  <div
    class="form-create d-flex justify-content-center align-items-center flex-column formLogin"
    v-if="loadData"
  >
    <div class="logo img-logo-header">
      <img
        v-if="logoShop"
        :src="logoShop"
        width="100%"
        height="100%"
        style="object-fit: contain; width: 100%; height: 100%"
      />
    </div>
    <div class="w-100 mb-3 p-2 p-sm-4">
      <div
        style="
            padding: 5px 15px !important;
            border: 1px solid #c3c4c7;
            bor"
      >
        <p class="sp-only login-title">メールアドレスでアカウントを作成</p>
        <form autocomplete="off">
          <div class="form-title">アカウント作成後コンテンツが表示されます</div>
          <div class="d-flex flex-column form-input">
            <label for="email" style="font-size: 16px"
              >ご登録のメールアドレス
              <span style="color: red" class="register_required"> (必須)</span>
            </label>
            <input
              v-model="email"
              type="email"
              name="email"
              id="email"
              placeholder="受信可能なメールアドレスを入力してください"
              autocomplete="new-password"
              style="color: black"
            />
          </div>
          <div v-if="jrg == false" class="d-flex flex-column form-input">
            <label for="email" style="font-size: 16px"
              ><span class="col-lg-12 px-0"
                >ご希望の英数字を含む半角8文字以上のパスワード</span
              >
              <span style="color: red" class="register_required"> (必須)</span>
            </label>
            <input
              v-model="password"
              type="password"
              name="password"
              id="password"
              placeholder="8文字以上の英数字でパスワードを入力してください"
              autocomplete="new-password"
              v-bind:class="{ 'error-input': errorField.password_request }"
            />
            <div
              class="message-error"
              style="margin-bottom: 20px"
              v-if="errorField.password_request"
            >
              {{ messageError.password_request }}
            </div>
          </div>
          <div v-if="jrg == false" class="d-flex flex-column form-input">
            <input
              v-model="confirmPasword"
              type="password"
              name="confirmPasword"
              id="confirmPassword"
              placeholder="パスワードを再入力してください。"
              autocomplete="new-password"
            />
          </div>
          <div v-if="jrg == false" class="btn-send">
            <b-button
              style="
                border-color: #333;
                background: #333;
                color: white;
                width: 50%;
              "
              v-on:click.prevent="createNewMember()"
              :disabled="isLoading"
            >
              <b-spinner v-if="isLoading" small></b-spinner>
              <span>アカウントを作成</span>
            </b-button>
          </div>
          <div v-else class="btn-send">
            <b-button
              style="
                border-color: #333;
                background: #333;
                color: white;
                width: 50%;
              "
              v-on:click.prevent="createQuickMember()"
              :disabled="isLoading"
            >
              <b-spinner v-if="isLoading" small></b-spinner>
              <span>アカウントを作成</span>
            </b-button>
          </div>
          <!-- <div class="text-center mb-5" style="font-size: 16px">
            <span
              >ログインすることにより、当社の
              <a href="" class="text-decoration-underline" style="color: black"
                >利用規約</a
              >
              および
              <a href="" class="text-decoration-underline" style="color: black"
                >プライバシー規約</a
              >約に同意したとみなされます。</span
            >
          </div> -->
          <!-- <div class="container login-sns">
            <div class="row">
              <div
                class="col-lg-12 mx-auto"
                style=""
                v-if="isCheckFacebook === 1"
              >
                <button
                  class="btn-common btn-facebook"
                  v-on:click.prevent="loginFacebook()"
                >
                  <i class="fab fa-facebook-square size-icon"></i>
                  Facebook でログインする
                </button>
              </div>
              <div class="col-lg-12" v-if="isCheckTwitter === 1">
                <a>
                  <button
                    class="btn-common btn-twitter"
                    v-on:click.prevent="loginTwitter()"
                  >
                    <i class="fab fa-twitter size-icon"></i>
                    Twitter でログインする
                  </button>
                </a>
              </div>
              <div class="col-lg-12" v-if="isCheckLine === 1">
                <button
                  class="btn-common btn-line"
                  v-on:click.prevent="loginLine()"
                >
                  <i class="fab fa-line size-icon"></i>
                  <i class="bi bi-activity"></i>
                  Line でログインする
                </button>
              </div>
              <div class="col" v-if="isCheckAmazon === 1">
                <button
                  class="btn-common btn-amazon"
                  v-on:click.prevent="loginAmazon()"
                >
                  <i class="fab fa-amazon size-icon"></i>
                  Amazon でログインする
                </button>
              </div>
            </div>
          </div>-->
        </form>
        <div class="w-100">
          当社の
          <a
            class="text-decoration-underline"
            style="color: black"
            v-b-modal.modal-terms
            v-if="htmlTerms"
            >利用規約</a
          >
          <span v-else>利用規約</span>
          および
          <a
            class="text-decoration-underline"
            style="color: black"
            v-b-modal.modal-policy
            v-if="htmlPolicy"
            >プライバシー規約</a
          >
          <span v-else>プライバシー規約</span>
          に同意いただける場合は、アカウントの作成をしてください。
        </div>
        <span v-if="onlRg == true" class="title2"
          >すでにアカウントをお持ちですか?</span
        >
        <div class="btn-send" style="" v-if="onlRg == true">
          <b-button
            v-on:click="ScreenLogin()"
            style="
              background: #f1f2f3;
              color: #5e5e64;
              border: 0.5px solid black;
            "
            :disabled="isLoading"
            >ログイン画面へ</b-button
          >
        </div>
        <div>
          <b-modal id="active-modal-page" hide-header hide-footer>
            <div class="d-block text-center">
              <h5>
                すでに同システム利用の他サイトにて、登録済みです。
                ログイン情報を共通化させますか？
                （登録不要で、すぐログインが可能です。
              </h5>
            </div>
            <div class="d-flex justify-content-center">
              <b-button
                class="mx-2 mt-3 btn btn-danger"
                block
                @click="confirmAccount()"
                >受け入れる</b-button
              >
              <b-button
                class="mx-2 mt-3 btn btn-secondary"
                block
                @click="closeModal()"
              >
                キャンセル
              </b-button>
            </div>
          </b-modal>
        </div>
      </div>
    </div>
    <div>
      <PagePopup />
    </div>
    <b-modal id="modal-terms" hide-header hide-footer>
      <div class="d-block text-center"></div>
      <div class="d-flex justify-content-center">
        <div class="w-100 customImg" v-html="htmlTerms" />
      </div>
    </b-modal>
    <b-modal
      id="modal-policy"
      hide-header
      hide-footer
      modal-class="modal-policy"
    >
      <div class="d-block text-center"></div>
      <div class="justify-content-center">
        <div class="w-100 customImg" v-html="htmlPolicy" />
      </div>
    </b-modal>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { Api } from "../../utils/http-common";
import { Constants } from "../../utils/constants";
import { FeedBack } from "@/utils/feedback.js";
import axios from "axios";
import no_image from "@/assets/img/logo_default.png";
import PagePopup from "@/views/default/PagePopup";
import { eventBus } from "../../main";

// import $ from "jquery";
export default {
  name: "CreateMember",
  components: {
    PagePopup,
  },
  data() {
    return {
      urlBackend: Constants.URL_BE_API,
      email: "",
      password: "",
      confirmPasword: "",
      firstName: "",
      lastName: "",
      isLoading: false,
      alertMessage: false,
      nick_name: "",
      postal_code: "",
      address: "",
      birthday: "",
      sex: 1,
      tel: "",
      receive_promotional_emails: true,
      shopId: null,
      errorField: {
        password_request: null,
      },
      messageError: {
        password_request: "",
      },
      logoShop: null,
      shop_id: localStorage.getItem(Constants.SHOP_ID),
      UrlRedirect: null,
      token_provide: null,
      dataToken: null,
      dataHTML: [],
      dataActiveToken: null,
      isCheckFacebook: null,
      isCheckLine: null,
      isCheckTwitter: null,
      isCheckAmazon: null,
      slagToken: this.$route.query.token
        ? this.$route.query.token.split("?")[0]
        : "",
      url_token: this.$route.query.url_token,
      redirect: this.$route.query.redirect,
      isDomain: null,
      loadData: false,
      htmlTerms: null,
      htmlPolicy: null,
      jrg: false,
      onlRg: true,
    };
  },
  computed: {
    ...mapGetters([
      "success",
      "message",
      "error",
      "ListSettingShop",
      "URLByToken",
      "dataGeneral",
      "detailShop",
      "modalShow",
    ]),
  },
  mounted() {
    const interval = setInterval(() => {
      if (this.modalShow == false) {
        this.showDetailFixed();
        if (localStorage.getItem("displayedFixed") == true) {
          clearInterval(interval);
        }
      }
    }, 50);
  },
  async created() {
    if (this.$route.query.jrg && this.$route.query.jrg == "true") {
      this.jrg = true;
    }
    if (this.$route.query.onlRg == "false") {
      this.onlRg = false;
    }
    this.$store.commit("set", ["modalShow", true]);
    if (!this.slagToken && !this.url_token && !this.redirect) {
      this.$store.commit("set", ["modalShow", false]);
      if (this.$route.params.shopId) {
        this.$router.push({
          name: "page404",
          params: {
            shopId: this.shopId,
          },
        });
      } else {
        this.$router.push({
          name: "page404 domain",
        });
      }
    }

    let request = {
      domain: Constants.DOMAIN,
      shop_id: this.$route.params.shopId,
    };
    let response = await this.getShopById(request);
    this.shop_id = response.data.id;

    if (this.slagToken) {
      const dataRequest = {
        slag_token: this.slagToken,
      };
      await this.getURLByToken(dataRequest);
    }
    // check token url
    if (this.url_token) {
      let response = await this.checkTokenUrl({
        token: this.url_token,
        shop_id: this.shop_id,
        type_acction: 3, // register
      });
      this.$store.commit("set", ["modalShow", false]);
      if (response != true) {
        this.$router.push({
          name: "page404 domain",
        });
      }
    }
    this.getListSettingShopUser({
      domain: Constants.DOMAIN,
      shop_id: localStorage.getItem(Constants.SHOP_ID),
    });
    await this.getGeneralByIdContent(localStorage.getItem(Constants.SHOP_ID));
    const _this = this;
    setTimeout(function () {
      _this.$store.commit("set", ["modalShow", false]);
    }, 3000);
  },
  watch: {
    success() {
      if (this.success) {
        this.isLoading = true;
        this.$store.commit("set", ["success", false]);
        this.$store.commit("set", ["message", ""]);
        // this.$router.push({
        //   name: "login shop",
        //   params: {
        //     shopId: this.shopId,
        //   },
        // });
      }
    },
    error() {
      if (this.error) {
        this.isLoading = false;
        this.$store.commit("set", ["message", ""]);
        this.$store.commit("set", ["error", false]);
      }
    },
    ListSettingShop() {
      this.dataShop = this.ListSettingShop;
      this.loadData = true;
      if (this.dataShop.length > 0) {
        if (this.dataShop[0].logo != null) {
          this.logoShop = Constants.URL_BE + "/" + this.dataShop[0].logo;
        } else {
          this.logoShop = no_image;
        }
      }
    },
    URLByToken() {
      if (this.URLByToken.is_quick_signup) {
        this.jrg = true;
      }
      // if (this.URLByToken.slag_name || this.URLByToken.slag_token) {
      //   this.onlRg = false;
      // }
      if (this.URLByToken.redirect_url) {
        this.UrlRedirect = this.URLByToken.redirect_url;
      } else if (this.URLByToken.url_redirect_provide) {
        this.UrlRedirect = this.URLByToken.url_redirect_provide;
      } else if (this.URLByToken.url_redirect_signup) {
        this.UrlRedirect = this.URLByToken.url_redirect_signup;
      }
      if (this.URLByToken.newsletters) {
        this.URLByToken.newsletters.forEach((element) => {
          this.dataHTML.push(element);
        });
      }
      this.htmlPolicy = this.URLByToken.policy;
      this.htmlTerms = this.URLByToken.terms;
    },
    dataGeneral() {
      this.isCheckFacebook = this.dataGeneral.facebook_status;
      this.isCheckLine = this.dataGeneral.line_status;
      this.isCheckTwitter = this.dataGeneral.twitter_status;
      this.isCheckAmazon = this.dataGeneral.amazone_status;
    },
  },
  methods: {
    ...mapActions({
      createMember: "createMember",
      getListSettingShopUser: "getListSettingShopUser",
      getURLByToken: "getURLByToken",
      activeAccount: "activeAccount",
      getGeneralByIdContent: "getGeneralByIdContent",
      getShopById: "getShopById",
      checkTokenUrl: "checkTokenUrl",
    }),
    setAlert() {
      this.alertMessage = true;
    },
    async createNewMember() {
      if (!this.email && !this.password) return;
      this.email = this.email ? this.email.trim() : "";
      this.password = this.password ? this.password.trim() : "";
      this.isLoading = true;
      const idShop = this.detailShop.id;
      // localStorage.setItem(Constants.SHOP_ID, this.$route.params.shopId);
      this.confirmPasword = this.confirmPasword
        ? this.confirmPasword.trim()
        : "";
      if (this.email == "") {
        this.$toasted.error("メールフィールドは必須です。");
        this.isLoading = false;
      } else if (!this.email.match(Constants.REGEX_EMAIL)) {
        this.$toasted.error("メールフィールドが無効です。");
        this.isLoading = false;
      } else if (this.password == "") {
        this.$toasted.error("パスワードフィールドは必須です。");
        this.errorField.password_request = true;
        this.messageError.password_request = FeedBack.PASSWORD_NULL;
        this.isLoading = false;
      } else if (Constants.CHECK_PASSWORD.test(this.password) === false) {
        this.$toasted.error("パスワードの形式は正しくありません");
        this.errorField.password_request = true;
        this.messageError.password_request = FeedBack.ALPHANUMERIC_PASSWORD;
        this.isLoading = false;
      } else if (this.password.length < 8) {
        this.$toasted.error("パスワードの形式は正しくありません");
        this.errorField.password_request = true;
        this.messageError.password_request = FeedBack.ALPHANUMERIC_PASSWORD;
        this.isLoading = false;
      } else if (this.password !== this.confirmPasword) {
        this.$toasted.error("パスワードが一致しませんでした。");
        this.isLoading = false;
      } else {
        // const { params } = this.$route;
        const formCreate = {
          shop_id: idShop,
          email: this.email,
          password: this.password,
          last_name: this.lastName,
          first_name: this.firstName,
          nick_name: this.nick_name,
          postal_code: this.postal_code,
          address: this.address,
          birthday: this.birthday,
          sex: this.sex,
          tel: this.tel,
          receive_promotional_emails: this.receive_promotional_emails,
          slag_token: this.slagToken,
          url_token: this.url_token,
          title: this.$route.meta.title,
          referrer_url: window.location.origin + this.$route.fullPath,
          url:
            window.location.origin +
            this.$router.resolve({
              name: this.shopId ? "library" : "library domain",
            }).href,
          id_button: window.document.activeElement.name,
        };
        const dataReturn = await this.$store.dispatch(
          "createMember",
          formCreate
        );
        this.isLoading = false;
        if (dataReturn.success) {
          var dataObject = new FormData();
          for (let i = 0; i < this.dataHTML.length; i++) {
            let formHTML = new DOMParser()
              .parseFromString(this.dataHTML[i].form_html, "text/html")
              .getElementsByTagName("*");
            for (let j = 0; j < formHTML.length; j++) {
              if (
                formHTML[j].getAttribute("name") !== null &&
                formHTML[j].getAttribute("name") === this.dataHTML[i].name
              ) {
                dataObject.append(formHTML[j].getAttribute("name"), this.email);
              } else if (
                formHTML[j].getAttribute("name") !== null &&
                formHTML[j].getAttribute("name") === this.dataHTML[i].email
              ) {
                dataObject.append(formHTML[j].getAttribute("name"), this.email);
              } else if (
                formHTML[j].getAttribute("name") !== null &&
                formHTML[j].getAttribute("name") !== this.dataHTML[i].name
              ) {
                dataObject.append(
                  formHTML[j].getAttribute("name"),
                  formHTML[j].getAttribute("value")
                );
              }
            }
            if (this.dataHTML[i].method.toLowerCase() === "get") {
              axios
                .get(this.dataHTML[i].action, {
                  params: dataObject,
                })
                .then(() => {});
              axios.get(this.dataHTML[i].action, dataObject).then(() => {});
            } else {
              axios.post(this.dataHTML[i].action, dataObject).then(() => {});
            }
          }
          this.$toasted.success(dataReturn.message);
          // if (dataReturn.message2 !== null) {
          //   this.$toasted.error(dataReturn.message2);
          //   this.isLoading = false;
          // }
          if (dataReturn.data.active_token) {
            this.dataActiveToken = dataReturn.data.active_token;
            this.$nextTick(() => {
              this.$bvModal.show("active-modal-page");
            });
          } else {
            const { access_token, user, expires_in, url_redirect } =
              dataReturn.data;
            const { user_type } = user;
            const expires_at = new Date(
              new Date().setMinutes(new Date().getMinutes() + expires_in)
            );
            if (user_type != 4) {
              this.message =
                "メールアドレスまたはパスワードが正しくありません。";
              this.setAlert();
              this.isLoading = false;
            } else {
              localStorage.setItem(Constants.TOKEN_USER, access_token);
              localStorage.setItem(Constants.USER_TYPE_USER, user_type);
              localStorage.setItem(Constants.EXPIRES_AT_USER, expires_at);
              localStorage.setItem(
                Constants.USER_ID,
                dataReturn.data.user.user_id
              );
              localStorage.setItem(
                Constants.EMAIL_USER,
                dataReturn.data.user.email
              );
              localStorage.setItem("popupSend", 1);
              localStorage.setItem(
                Constants.NORMAL_USER_INFO,
                JSON.stringify(user)
              );
              localStorage.setItem(
                Constants.IS_REMEMBER_USER,
                this.remember_me
              );
              if (url_redirect) {
                window.location.href = url_redirect;
              } else if (this.UrlRedirect) {
                window.location.href = this.UrlRedirect;
              } else if (this.$route.query.redirect) {
                this.$router.push(this.$route.query.redirect);
              } else {
                this.$router.push({
                  name: this.$route.params.shopId
                    ? "library"
                    : "library domain",
                  params: {
                    shopId: this.$route.params.shopId ? this.shopId : "",
                  },
                });
              }
            }
          }
        } else {
          this.$toasted.error(dataReturn.message);
          this.isLoading = false;
        }
      }
    },
    async createQuickMember() {
      if (!this.email && !this.password) return;
      this.email = this.email ? this.email.trim() : "";
      this.isLoading = true;
      const idShop = this.detailShop.id;
      // localStorage.setItem(Constants.SHOP_ID, this.$route.params.shopId);
      if (this.email == "") {
        this.$toasted.error("メールフィールドは必須です。");
        this.isLoading = false;
      } else if (!this.email.match(Constants.REGEX_EMAIL)) {
        this.$toasted.error("メールフィールドが無効です。");
        this.isLoading = false;
      } else {
        const formCreate = {
          shop_id: idShop,
          email: this.email,
          last_name: this.lastName,
          first_name: this.firstName,
          nick_name: this.nick_name,
          postal_code: this.postal_code,
          address: this.address,
          birthday: this.birthday,
          sex: this.sex,
          tel: this.tel,
          receive_promotional_emails: this.receive_promotional_emails,
          slag_token: this.slagToken,
          content_id: this.URLByToken.id,
          jrg: this.jrg,
        };
        const dataReturn = await this.$store.dispatch(
          "createQuickUser",
          formCreate
        );
        if (dataReturn.success) {
          var dataObject = new FormData();
          for (let i = 0; i < this.dataHTML.length; i++) {
            let formHTML = new DOMParser()
              .parseFromString(this.dataHTML[i].form_html, "text/html")
              .getElementsByTagName("*");
            for (let j = 0; j < formHTML.length; j++) {
              if (
                formHTML[j].getAttribute("name") !== null &&
                formHTML[j].getAttribute("name") === this.dataHTML[i].name
              ) {
                dataObject.append(formHTML[j].getAttribute("name"), this.email);
              } else if (
                formHTML[j].getAttribute("name") !== null &&
                formHTML[j].getAttribute("name") === this.dataHTML[i].email
              ) {
                dataObject.append(formHTML[j].getAttribute("name"), this.email);
              } else if (
                formHTML[j].getAttribute("name") !== null &&
                formHTML[j].getAttribute("name") !== this.dataHTML[i].name
              ) {
                dataObject.append(
                  formHTML[j].getAttribute("name"),
                  formHTML[j].getAttribute("value")
                );
              }
            }
            if (this.dataHTML[i].method.toLowerCase() === "get") {
              axios
                .get(this.dataHTML[i].action, {
                  params: dataObject,
                })
                .then(() => {});
              axios.get(this.dataHTML[i].action, dataObject).then(() => {});
            } else {
              axios.post(this.dataHTML[i].action, dataObject).then(() => {});
            }
          }
          this.$toasted.success(dataReturn.message);
          if (dataReturn.message2 !== null) {
            this.$toasted.error(dataReturn.message2);
            this.isLoading = false;
          }
          if (dataReturn.data.active_token) {
            this.dataActiveToken = dataReturn.data.active_token;
            this.$nextTick(() => {
              this.$bvModal.show("active-modal-page");
            });
          } else {
            const { access_token, user, expires_in } = dataReturn.data;
            const { user_type } = user;
            const expires_at = new Date(
              new Date().setMinutes(new Date().getMinutes() + expires_in)
            );
            if (user_type != 4) {
              this.message =
                "メールアドレスまたはパスワードが正しくありません。";
              this.setAlert();
              this.isLoading = false;
            } else {
              localStorage.setItem(Constants.TOKEN_USER, access_token);
              localStorage.setItem(Constants.USER_TYPE_USER, user_type);
              localStorage.setItem(Constants.EXPIRES_AT_USER, expires_at);
              localStorage.setItem(
                Constants.USER_ID,
                dataReturn.data.user.user_id
              );
              localStorage.setItem(
                Constants.EMAIL_USER,
                dataReturn.data.user.email
              );
              localStorage.setItem("popupSend", 1);
              localStorage.setItem(
                Constants.NORMAL_USER_INFO,
                JSON.stringify(user)
              );
              localStorage.setItem(
                Constants.IS_REMEMBER_USER,
                this.remember_me
              );
              if (this.UrlRedirect) {
                window.location.href = this.UrlRedirect;
              }
              if (this.$route.query.redirect) {
                this.$router.push(this.$route.query.redirect);
              }
              this.$router.push({
                name: this.$route.params.shopId ? "library" : "library domain",
                params: {
                  shopId: this.$route.params.shopId ? this.shopId : "",
                },
              });
            }
          }
        } else {
          this.$toasted.error(dataReturn.message);
          this.isLoading = false;
        }
      }
    },
    loginFacebook() {
      if (this.$route.params.shopId) {
        this.isDomain = 0;
      } else {
        this.isDomain = 1;
      }
      const dataRequest = {
        shop_id: this.shop_id,
        slag_token: this.slagToken,
        isDomain: this.isDomain,
      };
      Api.userRequestServer
        .get(
          `/auth/facebook?shop_id=${dataRequest.shop_id}&slag_token=${dataRequest.slag_token}&is_domain=${dataRequest.isDomain}`
        )
        .then((response) => {
          const { data } = response;
          window.location.href = `${data}`;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    loginTwitter() {
      if (this.$route.params.shopId) {
        this.isDomain = 0;
      } else {
        this.isDomain = 1;
      }
      const dataRequest = {
        shop_id: this.shop_id,
        slag_token: this.slagToken,
        isDomain: this.isDomain,
      };
      Api.userRequestServer
        .get(
          `/auth/twitter?shop_id=${dataRequest.shop_id}&slag_token=${dataRequest.slag_token}&is_domain=${dataRequest.isDomain}`
        )
        .then((response) => {
          const { data } = response;
          window.location.href = `${data}`;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    loginLine() {
      if (this.$route.params.shopId) {
        this.isDomain = 0;
      } else {
        this.isDomain = 1;
      }
      const dataRequest = {
        shop_id: this.shop_id,
        slag_token: this.slagToken,
        isDomain: this.isDomain,
      };
      Api.userRequestServer
        .get(
          `/auth/line?shop_id=${dataRequest.shop_id}&slag_token=${dataRequest.slag_token}&is_domain=${dataRequest.isDomain}`
        )
        .then((response) => {
          const { data } = response;
          window.location.href = `${data}`;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    loginAmazon() {
      if (this.$route.params.shopId) {
        this.isDomain = 0;
      } else {
        this.isDomain = 1;
      }
      const dataRequest = {
        shop_id: this.shop_id,
        slag_token: this.slagToken,
        isDomain: this.isDomain,
      };
      Api.userRequestServer
        .get(
          `/auth/amazon?shop_id=${dataRequest.shop_id}&slag_token=${dataRequest.slag_token}&is_domain=${dataRequest.isDomain}`
        )
        .then((response) => {
          const { data } = response;
          window.location.href = `${data}`;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    ScreenLogin() {
      if (this.url_token) {
        this.$router.push({
          name: this.$route.params.shopId ? "login shop" : "login shop domain",
          params: { shop_id: this.$route.params.shopId },
          query: { url_token: this.url_token, jlg: this.onlRg },
        });
        return;
      }
      if (this.redirect) {
        this.$router.push({
          name: this.$route.params.shopId ? "login shop" : "login shop domain",
          params: { shop_id: this.$route.params.shopId },
          query: { redirect: this.redirect, jlg: this.onlRg },
        });
        return;
      }

      if (this.URLByToken.slag_token) {
        this.token_provide = this.URLByToken.slag_token;
      } else if (this.URLByToken.token) {
        this.token_provide = this.URLByToken.token;
      }
      if (this.jrg == true) {
        this.$router.push({
          name: this.$route.params.shopId ? "login shop" : "login shop domain",
          params: { shop_id: this.$route.params.shopId },
          query: { token: this.token_provide, jrg: this.jrg },
        });
      } else {
        this.$router.push({
          name: this.$route.params.shopId ? "login shop" : "login shop domain",
          params: { shop_id: this.$route.params.shopId },
          query: { token: this.token_provide, jlg: "false" },
        });
      }
    },
    async confirmAccount() {
      const dataRequest = {
        token: this.dataActiveToken,
      };
      const dataReturn = await this.activeAccount(dataRequest);
      if (dataReturn.success) {
        const { access_token, user, expires_in } = dataReturn.data;
        const { user_type } = user;
        const expires_at = new Date(
          new Date().setMinutes(new Date().getMinutes() + expires_in)
        );
        if (user_type != 4) {
          this.message = "メールアドレスまたはパスワードが正しくありません。";
          this.setAlert();
          this.isLoading = false;
        } else {
          localStorage.setItem(Constants.TOKEN_USER, access_token);
          localStorage.setItem(Constants.USER_TYPE_USER, user_type);
          localStorage.setItem(Constants.EXPIRES_AT_USER, expires_at);
          localStorage.setItem(Constants.USER_ID, dataReturn.data.user.user_id);
          localStorage.setItem(
            Constants.EMAIL_USER,
            dataReturn.data.user.email
          );
          localStorage.setItem("popupSend", 1);
          localStorage.setItem(
            Constants.NORMAL_USER_INFO,
            JSON.stringify(user)
          );
          localStorage.setItem(Constants.IS_REMEMBER_USER, this.remember_me);
          this.$bvModal.hide("active-modal-page");
          if (this.UrlRedirect) {
            window.location.href = this.UrlRedirect;
          }
          if (this.$route.query.redirect) {
            this.$router.push(this.$route.query.redirect);
          }
          this.$router.push({
            name: this.$route.params.shopId ? "library" : "library domain",
            params: {
              shopId: this.$route.params.shopId ? this.shopId : "",
            },
          });
        }
        this.isLoading = false;
      }
    },
    closeModal() {
      this.$bvModal.hide("active-modal-page");
      this.isLoading = false;
    },
    showDetailFixed() {
      if (localStorage.getItem(Constants.ID_MODAL_FIXED)) {
        eventBus.$emit(
          "loadModalFixed",
          localStorage.getItem(Constants.ID_MODAL_FIXED)
        );
      }
    },
  },
};
</script>
<style scoped>
.formLogin {
  width: 540px !important;
  margin: auto;
  background-color: rgba(255, 255, 255, 0.8) !important;
  padding: 20px;
  border-radius: 10px;
}

/* Fix bug login don't show background */
@media screen and (max-width: 767px) {
  .formLogin {
    width: 80% !important;
  }
}
@media screen and (min-width: 768px) {
  .formLogin {
    width: 540px !important;
  }
}
@media screen and (min-width: 540px) {
  .formLogin {
    width: 520px !important;
  }
}
@media screen and (max-width: 768px) and (orientation: landscape) {
  .formLogin {
    width: 540px !important;
  }
}

.img-logo-header {
  width: 286px;
  height: 79px;
}
.error-input {
  border: solid 1px #e55353 !important;
  margin-bottom: 0px !important;
}
.message-error {
  color: #e55353;
}
.customTitleContent {
  color: #3c434a;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  line-height: 1.4;
  font-weight: bold;
  font-size: 18px;
}
.customAmountContent {
  color: #900;
  font-weight: bold;
  font-size: 23px;
  text-align: right;
  padding: 7px 0;
}
.totalAmount {
  float: right;
  text-align: right;
  color: #d45452;
  font-size: 23px;
  font-weight: bold;
}
.title2 {
  color: #72777c;
  width: 100%;
  text-align: center;
  font-weight: bolder;
  font-size: 18px;
  margin-top: 2em !important;
  margin-bottom: 1em !important;
  display: flex;
  align-items: center;
}
.title2::before,
.title2::after {
  border-top: 2px solid;
  content: "";
  flex-grow: 1;
}
.title2:before {
  margin-right: 0.5rem;
}
.title2:after {
  margin-left: 0.5rem;
}
.auth-layout .auth-form {
  width: 540px !important;
}
#modal-policy___BV_modal_outer_::v-deep p {
  margin-bottom: 0;
}
#modal-terms___BV_modal_outer_::v-deep p {
  margin-bottom: 0;
}
</style>
